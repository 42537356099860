<template>
    <div class="card mb-5 mb-xxl-8 pt-0 business-teams" v-if="!filterLoading">
        <div class="card-body pt-6 px-0 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap mb-5 settings-section">
                <div class="me-sm-7 mb-4 d-flex justify-content-center justify-content-sm-start">
                    <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img :src="businessData.icon_path" alt="image"/>
                    </div>
                </div>

                <div class="flex-grow-1">
                    <div
                        class="d-flex justify-content-between align-items-start flex-wrap mb-2 flex-column flex-sm-row align-items-center">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-items-center justify-content-center justify-content-sm-start mb-2">
                                <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{
                                        businessData.name
                                    }}</a>
                            </div>
                            <div
                                class="d-flex flex-wrap justify-content-center justify-content-sm-start fw-bold fs-6 mb-4 pe-2">
                                <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <inline-svg src="/media/buying/icons/business.svg"/>
                                    </span>
                                    <span
                                        v-for="(industry, index) in businessData.industries"
                                        :key="index"
                                    >
                                        {{ index < businessData.industries.length - 1 ? industry + ', ' : industry }}
                                    </span>
                                </a>
                                <a href="#"
                                   class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <inline-svg class="me-2" src="/media/buying/icons/user.svg"/>
                                    </span>
                                    {{ businessData.country }}
                                </a>
                            </div>
                        </div>
                        <div
                            v-if="canInviteColleague"
                            class="d-flex align-items-center justify-content-center flex-wrap gap-6">
                            <CustomLoader v-if="loading"/>
                            <template v-else>
                                <a @click.prevent="openInvitedModal" class="see-all-invited-btn">
                                    {{ $t('See all Invited') }}
                                </a>
                                <button class="engage-invite-toggle btn main-btn px-15" @click="addNewUserModal = true">
                                    {{ $t("Invite Colleague") }}
                                </button>
                            </template>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap flex-stack">
                        <div class="d-flex flex-wrap user-counter">
                            <div class="border border-gray-300 rounded min-w-175px py-3 px-4 mb-3 average_score me-6">
                                <div class="d-flex flex-column align-items-center">
                                    <div class="fw-bold fs-6 text-gray-400">{{ $t("Average Score") }}</div>
                                    <div class="fs-1 fw-boldest">
                                        <CustomLoader v-if="loading"/>
                                        <span v-else>{{ getBusinessInfo.score }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-gray-300 rounded min-w-175px py-3 px-4 mb-3  me-6">
                                <div class="d-flex flex-column align-items-center">
                                    <div class="fw-bold fs-6 text-gray-400">{{ $t("Total Members") }}</div>
                                    <div class="fs-1 fw-boldest">
                                        <CustomLoader v-if="loading"/>
                                        <span v-else>{{ users.length }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="border border-gray-300 rounded min-w-175px py-3 px-4 mb-3 status-danger">
                                <div class="d-flex flex-column align-items-center">
                                    <div class="fw-bold fs-6 text-gray-400">{{ $t("Admins") }}</div>
                                    <div class="fs-1 fw-boldest">
                                        <CustomLoader v-if="loading"/>
                                        <span v-else>
                                            {{ users.filter(item => item.role === UserRoleEnum.ADMIN).length }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--  <div class="mb-8 card py-3">-->
    <!--    <div class="d-flex justify-content-between align-items-center">-->
    <!--      <div class="searchWithIcon">-->
    <!--        <label>-->
    <!--          <inline-svg src="/media/buying/icons/general/gen021.svg" />-->
    <!--        </label>-->
    <!--        <input-->
    <!--          class="border-0"-->
    <!--          v-model="searched"-->
    <!--          type="text"-->
    <!--          :placeholder="$t('Search Colleague')"-->
    <!--        >-->
    <!--      </div>-->

    <!--      <el-drawer size="200" v-model="isTeamFilterVisible" :with-header="false">-->
    <!--        <div class="card shadow-none rounded-0 w-100">-->
    <!--          <div class=" d-flex align-items-center" id="kt_filter_header">-->
    <!--            <button-->
    <!--              type="button"-->
    <!--              class="btn btn-sm btn-icon explore-btn-dismiss"-->
    <!--              id="kt_filter_close"-->
    <!--              @click="isTeamFilterVisible = false"-->
    <!--            >-->
    <!--            <span class="">-->
    <!--              <inline-svg src="/media/buying/icons/general/arr061.svg" />-->
    <!--            </span>-->
    <!--            </button>-->
    <!--            <h5 class="mb-0 fs-2 fw-bold">{{ $t("Filters") }}</h5>-->
    <!--          </div>-->
    <!--          <div class="card-body filter-body" id="kt_help_body">-->
    <!--            <el-form>-->
    <!--              <div>-->
    <!--                <el-form-item>-->
    <!--                  <label>{{ $t("Banks") }}</label>-->
    <!--                  <el-select-->
    <!--                    v-model="filterData.filter.bank_ids"-->
    <!--                    filterable-->
    <!--                    multiple-->
    <!--                    collapse-tags-->
    <!--                    size="large"-->
    <!--                    :placeholder="$t('Select Banks')"-->
    <!--                  >-->
    <!--                    <el-option-->
    <!--                      v-for="item in businessBanks"-->
    <!--                      :key="item.id"-->
    <!--                      :label="item.name"-->
    <!--                      :value="item.id"-->
    <!--                    />-->
    <!--                  </el-select>-->
    <!--                </el-form-item>-->
    <!--              </div>-->
    <!--              <div class="mt-5">-->
    <!--                <el-form-item>-->
    <!--                  <label>{{ $t("Country of the Bank") }}</label>-->
    <!--                  <el-select-->
    <!--                    v-model="filterData.filter.countries"-->
    <!--                    size="large"-->
    <!--                    filterable-->
    <!--                    multiple-->
    <!--                    collapse-tags-->
    <!--                    :placeholder="$t('Select Countries')"-->
    <!--                  >-->
    <!--                    <el-option-->
    <!--                      v-for="(item, index) in businessCountries"-->
    <!--                      :key="index"-->
    <!--                      :label="item"-->
    <!--                      :value="item"-->
    <!--                    />-->
    <!--                  </el-select>-->
    <!--                </el-form-item>-->
    <!--              </div>-->
    <!--              <div class="mt-5">-->
    <!--                <el-form-item>-->
    <!--                  <label>{{ $t("Product Areas") }}</label>-->
    <!--                  <el-select-->
    <!--                    v-model="filterData.filter.product_areas"-->
    <!--                    size="large"-->
    <!--                    filterable-->
    <!--                    multiple-->
    <!--                    collapse-tags-->
    <!--                    :placeholder="$t('Select Product Areas')"-->
    <!--                  >-->
    <!--                    <el-option-->
    <!--                      v-for="(item, index) in businessProductAreas"-->
    <!--                      :key="index"-->
    <!--                      :label="item"-->
    <!--                      :value="item"-->
    <!--                    />-->
    <!--                  </el-select>-->
    <!--                </el-form-item>-->
    <!--              </div>-->
    <!--              <button class="btn main-btn w-100 mt-15" @click.prevent="applyTeamFilters">{{ $t("Apply Filters") }}-->
    <!--              </button>-->
    <!--            </el-form>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </el-drawer>-->

    <!--      <button-->
    <!--        @click="isTeamFilterVisible = true"-->
    <!--        class="btn main-btn px-15 h-40px"-->
    <!--      >-->
    <!--        <inline-svg class="filterIcon w-20px" src="/media/buying/icons/general/gen031.svg" />-->
    <!--        {{ $t("Filter") }}-->
    <!--      </button>-->
    <!--    </div>-->
    <!--  </div>-->

    <CustomLoader height="400px" v-if="loading"/>

    <template v-else>
        <div class="card pt-3 ">
            <div class="card-header cursor-pointer px-0 pb-7 pt-5">
                <div class="card-title m-0 mb-lg-0 mb-5 me-3">
                    <h3 class="fw-bolder m-0 ">{{ $t("Admins") }}</h3>
                    <span class="text-with-bg ms-3">{{ adminsList.length }}</span>
                </div>
            </div>
            <TeamsTable
                :teamsData="adminsList"
                :showAction="currentUser.isAdmin"
                :authUser="currentUser"
                @handle-delete="visibleDeleteModal = true; userId = $event"
                @handle-view="viewUser"
                @handle-status-change="toggleUserBlockStatus"
                @handle-view-profile="viewAuthUserProfile"
                @handle-role-change="changeUserRole"
            />
        </div>
        <ConfirmationModal
            title="Deleting User"
            description="Are you sure you want to delete this user?"
            btnName="Delete"
            :popupVisible="visibleDeleteModal"
            @handleClose="visibleDeleteModal = false"
            @handleConfirm="deleteUser"
        />
        <div class="card pt-3 mt-8">
            <div class="card-header cursor-pointer px-0 pb-7 pt-5">
                <div class="card-title m-0 mb-lg-0 mb-5 me-3">
                    <h3 class="fw-bolder m-0 ">{{ $t("Users") }}</h3>
                    <span class="text-with-bg ms-3">{{ usersList.length }}</span>
                </div>
            </div>
            <TeamsTable
                :teamsData="usersList"
                :showAction="currentUser.isAdmin"
                :authUser="currentUser"
                @handle-delete="visibleDeleteModal = true; userId = $event"
                @handle-view="viewUser"
                @handle-status-change="toggleUserBlockStatus"
                @handle-view-profile="viewAuthUserProfile"
                @handle-role-change="changeUserRole"
            />
        </div>

        <!-- Invite Colleague Drawer -->
        <InviteColleagueDrawer
            v-model="addNewUserModal"
            :permissionGroups="permissionGroups"
            @fetchUsers="fetchUsers"
            @handleClose="addNewUserModal=false"
        />

        <!-- See all Invited drawer -->
        <SeeAllBusinessInvitedDrawer
            v-if="seeAllInvitedModal"
            v-model="seeAllInvitedModal"
            :invited-data="invited"
            :loading="loadingInvited"
            @handleDeleteUser="handleDeleteInvitedUser"
            @handleClose="seeAllInvitedModal=false"
            @addNewUser="seeAllInvitedModal=false;addNewUserModal=true;"
        />
    </template>
</template>
<script>
import store from "@/store";
import { BusinessTeamFilterModel } from "@/store/models/filter/BusinessTeamFilterModel";
import TeamsTable from "./TeamsTable";
import NotificationService from "@/buying-teams/services/NotificationService";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import ConfirmationModal from "../../../shared-components/modals/ConfirmationModal";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {diagLog} from "@/core/helpers/GlobalHelper";
import SeeAllBusinessInvitedDrawer from "@/buying-teams/pages/business/teams/SeeAllBusinessInvitedDrawer";
import InviteColleagueDrawer from "@/buying-teams/pages/business/teams/InviteColleagueDrawer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "Teams",

    components: {
        CustomLoader,
        InviteColleagueDrawer,
        SeeAllBusinessInvitedDrawer,
        ConfirmationModal,
        TeamsTable
    },

    data() {
        return {
            UserRoleEnum,
            filterLoading: true,
            isTeamFilterVisible: false,
            visibleDeleteModal: false,
            seeAllInvitedModal: false,
            addNewUserModal: false,
            searched: "",
            users: [],
            invited: [],
            userId: null,
            loading: true,
            loadingInvited: false,
            permissionGroups: [],
            filterData: {
                page: 0,
                type: "team",
                search: "",
                filter: {
                    bank_ids: [],
                    time_period: "*",
                    product_areas: ["*"],
                    countries: []
                }
            }
        };
    },

    computed: {
        usersList() {
            return this.filteredUsersList.filter(item => item.role === UserRoleEnum.USER);
        },
        adminsList() {
            return this.filteredUsersList.filter(item => item.role === UserRoleEnum.ADMIN);
        },
        currentUser() {
            return store.getters.currentUser;
        },
        businessTeamFilter() {
            return store.getters.getBusinessFiltersState.teams;
        },
        businessBanks() {
            return store.getters.businessBanks;
        },
        businessCountries() {
            return store.getters.businessCountries;
        },
        businessProductAreas() {
            return store.getters.businessProductAreas;
        },
        businessData() {
            return store.getters.business;
        },
        filteredUsersList() {
            return this.users.filter(user => {
                const fullName = user.first_name.toLowerCase() + " " + user.last_name.toLowerCase();
                return fullName.includes(this.searched.toLowerCase().trim())
            })
        },
        getBusinessInfo() {
            return store.getters.getBusinessInfoData;
        },
        canInviteColleague() {
            return (this.currentUser.role === UserRoleEnum.USER && this.currentUser.can(BusinessUserPermissionTypeEnum.PU_INVITE_USER) ||
                (this.currentUser.role === UserRoleEnum.ADMIN && (this.currentUser.can(BusinessUserPermissionTypeEnum.PA_INVITE_USER) ||
                    this.currentUser.can(BusinessUserPermissionTypeEnum.INVITE_ADMIN))))
        }
    },
    created() {
        setCurrentPageBreadcrumbs({title: `${this.$t('hi')} ${this.currentUser.first_name},  <span>${this.$t('welcomeBack')}</span>`});
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.TEAMS);
        this.filterLoading = false;

        this.setFilters(this.businessTeamFilter);

        store.dispatch('getBusinessInfo');

        this.users.length = 0;
        this.loading      = true;
        this.getTeamList(this.filterData).finally(() => {
            this.loading = false;
        });
    },

    methods: {
        async getTeamList(filter) {
            await store.dispatch("getTeamList", filter).then((res) => {
                this.users   = res.team
                this.invited = res.invited
                this.permissionGroups = res.permissionGroups
            })
        },
        async applyTeamFilters() {
            diagLog("FILTERS = ", new BusinessTeamFilterModel(this.filterData.filter));
            this.setFilters(new BusinessTeamFilterModel(this.filterData.filter));
            await this.getTeamList(this.filterData);
            this.isTeamFilterVisible = false
        },
        setFilters(filters) {
            this.filterData.filter = filters;
        },
        async deleteUser() {
            await store.dispatch("deleteUser", this.userId)
                .then(res => {
                    if (res) this.users = this.users.filter(el => el.id !== this.userId);
                });
        },
        viewUser(id) {
            this.$router.push("/business/teams/user/" + id);
        },
        async toggleUserBlockStatus(id, isBlocked) {
            if (isBlocked) {
                await store.dispatch("unblockUser", id)
                    .then(res => {
                        if (res) this.changeUserStatus(id);
                    });
            } else {
                await store.dispatch("blockUser", id)
                    .then(res => {
                        if (res) this.changeUserStatus(id);
                    });
            }
        },
        changeUserStatus(id) {
            let user        = this.users.find(el => el.id === id);
            user.is_blocked = !user.is_blocked;
            NotificationService.swalCustom({
                title: "Success",
                icon: "success"
            });
        },
        viewAuthUserProfile() {
            redirectToRouteWithName('user-setting-overview');
        },
        async changeUserRole(id, role) {
            const userData = {...this.currentUser}
            userData.role  = role;

            await store.dispatch("updateUserDetails", { role, id, is_edit_role: true })
                .then(res => {
                    if (res) {
                        let user  = this.users.find(el => el.id === id);
                        user.role = role;
                        NotificationService.swalCustom({
                            title: "Success",
                            icon: "success"
                        });
                    }
                }).catch(err => {
                    console.log(err, "----err");
                })
        },
        fetchUsers() {
            this.addNewUserModal = false;
            this.getTeamList(this.filterData);
        },
        handleDeleteInvitedUser(user) {
            this.invited = this.invited.filter(el => el.id !== user.id);
        },
        openInvitedModal() {
            this.seeAllInvitedModal = true;
            this.loadingInvited     = true;
            this.getTeamList(this.filterData).finally(() => {
                this.loadingInvited = false;
            })
        }
    }
};
</script>
<style lang="scss">
.filterIcon {
    path {
        fill: #fff
    }
}

.searchWithIcon {
    width: 50%;
    border-radius: 26px;
    padding: 0 20px;

    label {
        svg {
            width: 22px;

            path, rect {
                fill: #ccc;
            }
        }
    }

    input {
        width: 90%;
        border: none;
        padding: 10px;

        &::placeholder {
            font-size: 16px;
            line-height: 25px;
            color: #2B2B2B;
            opacity: 0.3;
        }

        &:active, &:focus-visible {
            border: none;
            outline: none;
        }
    }

    @media screen and (max-width: 992px) {
        padding: 0 10px;
    }
}

.business-teams {
    .user-counter {
        .average_score {
            position: relative;
            margin-right: 39px !important;

            &:before {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background: #000000;
                top: 0;
                right: -20px;
                opacity: 0.1;
            }
        }
    }

    .see-all-invited-btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #435BF4;
        cursor: pointer;
        transition: .2s ease-in-out;

        &:hover {
            opacity: 0.7;
        }
    }

    @media (max-width: 685px) {
        .settings-section .user-counter {
            margin-top: 0;
        }
    }
    @media (max-width: 576px) {
        .user-counter > div.average_score,
        .user-counter > div {
            margin-right: 0 !important;
            width: 100%;

            &:before {
                display: none;
            }
        }
    }
}
</style>
