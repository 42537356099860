<template>
    <el-table
        :data="teamsData"
        style="width: 100%"
        class="teams-table"
        header-cell-class-name="bg-light-dark py-7"
    >
        <el-table-column
            prop="avatar"
            :label="$t('Avatar')"
            width="100"
            v-slot="avatar">
            <img
                width="50"
                height="50"
                class="border rounded-circle user-icon_path cursor-pointer"
                :src="avatar.row.icon_path"
                @click="onRowClick(avatar.row)"
                alt=""
            >
        </el-table-column>
        <el-table-column
            prop="name"
            :label="$t('Name')"
            width="300"
            v-slot="name">
            <p
                class="fw-bold fs-3 mb-0 cursor-pointer width-max-content"
                @click="onRowClick(name.row)"
            >
                {{ name.row.first_name + " " + name.row.last_name }}
            </p>
            <p class="teams-table__name">
                <span v-if="name.row.role === UserRoleEnum.ADMIN">{{ $t("Admin") }}</span>
                <strong v-if="name.row.is_blocked">{{ $t("Blocked") }}</strong>
            </p>
        </el-table-column>
        <el-table-column
            prop="jobTitle"
            :label="$t('Job Title')"
            width="300"
            v-slot="jobTitle">
            <p class="fw-bold fs-3 text-gray-800 mobile-title">{{ $t("Job Title") }}:</p>
            <p class="fw-bold fs-3 text-gray-500">{{ jobTitle.row.job_title }}</p>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="banks"
            width="300"
            :label="$t('Banks')"
            v-slot="banks">
            <p class="fw-bold fs-3 text-gray-800 mobile-title">{{ $t("Banks") }}:</p>
            <span
                v-for="(bank, i) in banks.row.banks"
                class="fw-bold fs-3 text-gray-500">
                {{ i < banks.row.banks.length - 1 ? bank.name + ", " : bank.name }}
            </span>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="country"
            :label="$t('Country of residence')"
            v-slot="countries"
        >
            <span class="fw-bold fs-3 text-gray-500">
                <p class="fw-bold fs-3 text-gray-800 mobile-title">{{ $t("Country of residence") }}:</p>
                {{ countries.row.country || "-" }}
            </span>
        </el-table-column>
        <el-table-column
            width="150"
            v-slot="actions">
            <div
                class="me-0 d-flex justify-content-end pe-2"
                v-if="showAction && authUser.id !== actions.row.id"
            >
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                        <i class="bi bi-three-dots fs-1 fw-bolder"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="a" @click="$emit('handle-view', actions.row.id)">
                                {{ $t(("View")) }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                command="b"
                                @click="$emit('handle-status-change', actions.row.id, actions.row.is_blocked)"
                            >
                                {{ actions.row.is_blocked ? $t("Unblock") : $t("Block") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="actions.row.role === UserRoleEnum.ADMIN && currentUser.can(BusinessUserPermissionTypeEnum.PROMOTE_DEMOTE_USER)"
                                command="b"
                                @click="$emit('handle-role-change', actions.row.id, UserRoleEnum.USER)"
                            >
                                {{ $t("Change to user") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="actions.row.role === UserRoleEnum.USER && currentUser.can(BusinessUserPermissionTypeEnum.PROMOTE_DEMOTE_USER)"
                                command="b"
                                @click="$emit('handle-role-change', actions.row.id, UserRoleEnum.ADMIN)"
                            >
                                {{ $t("Promote to admin") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="(actions.row.role === UserRoleEnum.ADMIN && currentUser.can(BusinessUserPermissionTypeEnum.DELETE_ADMIN)) ||
                                    (actions.row.role === UserRoleEnum.USER && currentUser.can(BusinessUserPermissionTypeEnum.DELETE_USER))"
                                command="c"
                                @click="$emit('handle-delete', actions.row.id)"
                            >
                                {{ $t("Delete") }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div
                v-else-if="authUser.id === actions.row.id"
                @click="$emit('handle-view-profile', actions.row.id)"
                class="pointer text-primary d-flex justify-content-end pe-2"
            >
                {{ $t("View My Profile") }}
            </div>
            <div
                v-else
                @click="$emit('handle-view', actions.row.id)"
                class="pointer text-primary d-flex justify-content-end pe-2"
            >
                {{ $t("View Profile") }}
            </div>
        </el-table-column>
    </el-table>
</template>
<script>
import store from "@/store";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "TeamsTable",

    props: ["teamsData", "showAction", "authUser"],

    emits: ["handle-view", "handle-status-change", "handle-role-change", "handle-delete", "handle-view-profile"],

    data() {
        return {
            BusinessUserPermissionTypeEnum,
            UserRoleEnum,
        }
    },

    computed: {
        currentUser() {
            return store.getters.currentUser;
        },
    },

    methods: {
        onRowClick(row) {
            if (this.authUser.id === row.id) {
                this.$emit("handle-view-profile", row.id);
            } else {
                this.$emit("handle-view", row.id);
            }
        }
    }
};
</script>
<style lang="scss">
.teams-table {
    .mobile-title {
        display: none;
    }

    .user-icon_path {
        object-fit: cover;
        object-position: top;
    }

    .width-max-content {
        width: max-content;
    }

    &__name {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #A7A7A7;
            margin-right: 12px;
            margin-top: 2px;
        }

        strong {
            font-weight: 500;
            font-size: 10px;
            line-height: 158.02%;
            color: #E22D21;
            text-transform: uppercase;
            margin-top: 2px;
        }
    }

    @media (max-width: 768px) {
        &.el-table::before {
            display: none;
        }
        .mobile-title {
            display: inline-block;
            margin-right: 10px;
        }
        .el-table__header-wrapper {
            display: none;
        }
        .el-table__body {
            display: flex;
            width: 100% !important;

            colgroup {
                display: none;
            }

            .el-table__row {
                display: flex;
                flex-wrap: wrap;

                &:not(:last-child) {
                    border-bottom: 1px solid #EFF2F5;
                    padding-bottom: 15px;
                }

                .el-table__cell {
                    border: none !important;

                    &:nth-child(1) {
                        width: 70px;
                    }

                    &:nth-child(2) {
                        width: calc(100% - 70px);
                        display: flex;
                        align-items: center;
                    }

                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        width: 100%;
                        padding-top: 5px;
                        padding-bottom: 5px;

                        .cell {
                            display: flex;

                            &.el-tooltip {
                                width: auto !important;
                                display: flex;
                                flex-wrap: wrap;
                            }

                            p {
                                margin-bottom: 0 !important;
                            }

                            span {
                                margin-right: 5px;
                            }
                        }
                    }

                    &:nth-child(6) {
                        position: absolute;
                        background: transparent;
                        right: 0;
                        width: max-content;
                        padding: 0;
                        padding-top: 5px;

                        .text-primary {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
</style>
