<template>
    <el-drawer
        v-model="addNewUserModal"
        :title="$t('Add New User')"
        append-to-body
        custom-class="add-new-user"
        size="516px"
        :z-index="1000"
    >
        <div class="drawer-content">
            <el-form
                ref="inviteUserRef"
                :model="inviteUserForm"
                :rules="inviteUserFormRules"
            >
                <el-form-item class="mb-3" prop="email">
                    <label>{{ $t("Email") }}*</label>
                    <el-input v-model="inviteUserForm.email" />
                </el-form-item>

                <div class="hint d-flex align-items-center mt-5">
                    <img src="/media/buying/icons/info_black.svg" width="13" class="me-2" alt="">
                    <p class="fs-7 text-gray-500 mb-0">
                        {{ $t("User will get verification link to this email id.") }}
                    </p>
                </div>
                <el-form-item prop="role">
                    <label>{{ $t("Define Access") }}</label>
                    <el-radio-group v-model="inviteUserForm.role" size="large" class="priority w-100">
                        <el-radio-button :disabled="!hasAccessToInviteAdmin" label="admin">
                            {{ $t("Admin") }}
                            <el-tooltip
                                v-if="!hasAccessToInviteAdmin"
                                :content="$t('Action not permitted')"
                                effect="dark"
                                placement="top"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                            </el-tooltip>
                        </el-radio-button>
                        <el-radio-button :disabled="!hasAccessToInviteUser" label="standard">
                            {{ $t("User") }}
                            <el-tooltip
                                v-if="!hasAccessToInviteUser"
                                :content="$t('Action not permitted')"
                                effect="dark"
                                placement="top"
                            >
                                <img src="/media/buying/icons/info_black.svg" alt="">
                            </el-tooltip>
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    class="mt-4"
                    v-if="
                        inviteUserForm.role !== UserRoleEnum.ADMIN &&
                        currentUser.role === UserRoleEnum.ADMIN &&
                        currentUser.can(BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS) &&
                        (permissionGroups && permissionGroups.length)
                    ">
                    <label>
                        {{ $t("Permissions Group") }}

                        <el-tooltip
                            class="box-item"
                            :append-to-body="false"
                            effect="dark"
                            placement="bottom-start"
                        >
                            <img src="/media/buying/icons/info_black.svg" alt="">
                            <template #content>
                                <div style="max-width: 250px">
                                    {{ $t('If no Permission Group is assigned, all permissions will be disabled for the user. They can be enabled later on.') }}
                                </div>
                            </template>
                        </el-tooltip>
                    </label>

                    <el-select
                        class="w-100"
                        v-model="inviteUserForm.group_id"
                        :placeholder="$t('No Permission Group')"
                    >
                        <el-option
                            v-for="item in permissionGroups"
                            :value="item.id"
                            :label="item.name"
                        >
                            {{ item.name }}
                        </el-option>
                    </el-select>
                </el-form-item>
                <button
                    class="btn main-btn w-100 mt-4"
                    :data-kt-indicator="buttonIndicator"
                    :disabled="buttonIndicator === 'on'"
                    @click.prevent="inviteUser"
                >
                    <span class="indicator-label"> {{ $t("Add User") }}</span>

                    <span class="indicator-progress">
                        {{ $t("pleaseWait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </el-form>
        </div>
    </el-drawer>
</template>

<script>

import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { UserRoleEnum } from "@/store/enums/UserRoleEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "InviteColleagueDrawer",
    props: ["addNewUserModal", "permissionGroups"],
    emits: ["handleClose", "fetchUsers"],
    computed: {
        currentUser() {
            return store.getters.currentUser;
        },
        hasAccessToInviteAdmin() {
            return (this.currentUser.role === UserRoleEnum.ADMIN && this.currentUser.can(BusinessUserPermissionTypeEnum.INVITE_ADMIN));
        },
        hasAccessToInviteUser() {
            return (this.currentUser.role === UserRoleEnum.USER && this.currentUser.can(BusinessUserPermissionTypeEnum.PU_INVITE_USER)) ||
                (this.currentUser.role === UserRoleEnum.ADMIN && this.currentUser.can(BusinessUserPermissionTypeEnum.PA_INVITE_USER))
        },
    },

    async mounted() {
        if (this.hasAccessToInviteUser) {
            this.inviteUserForm.role = UserRoleEnum.USER;
        } else if (this.hasAccessToInviteAdmin) {
            this.inviteUserForm.role = UserRoleEnum.ADMIN;
        }
        await store.dispatch("fetchStaticData", StaticDataEnum.COUNTRIES);
    },

    data() {
        return {
            buttonIndicator: "off",
            UserRoleEnum,
            BusinessUserPermissionTypeEnum,
            inviteUserFormRules: {
                email: [
                    {
                        required: true,
                        message: this.$t("Please input Email"),
                        trigger: "change"
                    },
                    {
                        required: true,
                        type: "email",
                        message: this.$t("Please input correct email"),
                        trigger: "change"
                    }
                ],
                role: [
                    {
                        required: true,
                        message: this.$t("Please select User Type"),
                        trigger: "change"
                    }
                ]
            },
            inviteUserForm: {
                email: "",
                role: "",
                group_id: null,
            }
        };
    },
    methods: {
        inviteUser() {
            this.$refs.inviteUserRef.validate(valid => {
                if (valid) {
                    this.buttonIndicator = "on";
                    const payload = { ...this.inviteUserForm };
                    if (payload.role === UserRoleEnum.ADMIN || this.currentUser.role === UserRoleEnum.USER) {
                        delete payload.group_id;
                    }

                    store.dispatch("inviteUser", this.inviteUserForm)
                        .then(res => {
                            if (res) {
                                this.$emit("fetchUsers");
                                NotificationService.swalNotify({ confirmButtonText: this.$t("OK") });
                            }
                        })
                        .catch(err => {
                            console.log("error = ", err);
                        })
                        .finally(() => {
                            this.buttonIndicator = "off";
                        });
                }
            });
        }
    }
};
</script>
<style lang="scss">
.el-drawer.add-new-user {
    .drawer-content {
        padding: 0 15px 0 18px;

        .el-form-item__content {
            .el-radio-group {
                width: 100%;

                .el-radio-button {
                    margin-right: 15px;

                    .el-radio-button__inner {
                        padding: 8px 20px;
                        height: 41px;
                        background: #F4F5FE;
                        border: 1px solid #435BF4;
                        border-radius: 90px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: #435BF4;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            margin-left: 4px;
                        }
                    }

                    &.is-active {
                        .el-radio-button__inner {
                            background: #435BF4;
                            color: #fff;
                        }
                    }

                    &.is-disabled {
                        .el-radio-button__inner {
                            background: #ededed;
                            border-color: #cacaca;
                            color: #898989;
                            opacity: 0.6;
                        }
                    }
                }
            }

            label {
                font-weight: 500;
                font-size: 17px;
                line-height: 21px;
                color: #4F4F4F;
            }

            .el-input__inner {
                width: 100%;
                height: 53px;
                border-radius: 4px;
                background: #F6F6F6;
                border: none;
            }

            .el-select {
                .el-input__inner {
                    background: #FFFFFF;
                    border: 1px solid #E3E3E3;
                    border-radius: 5px;
                    height: 45px;
                }
            }
        }
    }

    .el-drawer__header {
        justify-content: flex-end;
        flex-direction: row-reverse;
        padding-top: 60px;

        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 13px;
            color: #000000;
            opacity: 0.87;
            flex: revert;
        }

        button {
            display: flex;
            margin-right: 5px;
        }
    }

    &__body {
        min-height: calc(100vh - 114px);
        overflow-y: auto;
    }

    @media (max-width: 520px) {
        width: 100% !important;
    }
}
</style>
