<template>
  <el-drawer
    v-model="AllInvitedModal"
    append-to-body
    custom-class="all-invited-drawer"
    :z-index="1000"
    size="769px"
    @close="$emit('handleClose')"
  >
    <template #title>
      <div class="all-invited-drawer__header">
        <h2>
          {{ $t('Invited Colleagues') }}
          <span v-if="!loading && invitedData.length">{{ invitedData.length }}</span>
        </h2>
        <button :disabled="loading" class="btn main-btn" @click="$emit('addNewUser')">
          {{ $t('Invite Colleague') }}
        </button>
      </div>
    </template>
    <CustomLoader v-if="loading" height="200px" />
    <div v-if="!loading" class="drawer-content">
      <InviteListItem
        v-if="invitedData.length"
        v-for="(item, key) in invitedData"
        :key="key"
        :item="item"
        :loadingOperation="loadingOperation"
        @refreshInvitation="handleRefreshInvitation"
        @deleteInvitation="handleDeleteInvitation"
      />
      <div class="empty-list-state pt-20" v-else>
        <div class="empty-list-state__title mb-6">
          {{ $t("No User Invited") }}
        </div>
        <img src="/media/buying/customerInvited.svg" alt="">
      </div>
    </div>
    <div class="drawer-footer" v-if="!loading && showFooter">
      <p>
        <img src="/media/buying/icons/info_outined.svg" alt="">
        {{ $t('You can find all the users who accepted the invite in the user/admin list') }}
      </p>
      <img src="/media/buying/icons/close.svg" @click="showFooter = false" width="20" alt="">
    </div>
  </el-drawer>
</template>

<script>
import InviteListItem from "@/buying-teams/shared-components/utils/InviteListItem";
import {diagLog} from "@/core/helpers/GlobalHelper";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
  name: "SeeAllBusinessInvitedDrawer",
  components: {CustomLoader, InviteListItem},
  props: ['AllInvitedModal', 'invitedData', 'loading'],
  emits: ['handleClose', 'addNewUser', 'handleDeleteUser'],
  data() {
    return {
      colleaguesInvite: [],
      showFooter: true,
      loadingOperation: false,
    }
  },
  methods: {
    handleRefreshInvitation(item) {
      diagLog('Refresh Invitation', item);
    },
    handleDeleteInvitation(item) {
      Swal.fire({
        title: this.$t('Deleting user invite'),
        text: this.$t('When deleted, the person invited will no longer be able to set-up their profile using the generated credentials.'),
        showCancelButton: true,
        confirmButtonText: this.$t('Delete Invitation'),
        customClass: 'delete-invitation',
        cancelButtonText: this.$t('Cancel')
      }).then((result) => {
        if (result.value) {
          this.loadingOperation = true;
          store.dispatch("deleteUser", item.id)
            .then((res) => {
              if (res) {
                this.$emit('handleDeleteUser', item);
                NotificationService.swalCustom({
                  title: "Success",
                  icon: "success"
                });
              }
            })
            .finally(() => {
              this.loadingOperation = false;
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.el-drawer.all-invited-drawer {
  .all-invited-drawer__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 24px;
      color: #000000;
      span {
        background: #ECECEC;
        border-radius: 3px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        padding: 3px 5px;
        margin-left: 12px;
      }
    }
  }
  .el-drawer__header {
    justify-content: flex-end;
    flex-direction: row-reverse;
    padding-top: 60px;
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 13px;
      color: #000000;
      opacity: 0.87;
      flex: revert;
    }
    button {
      display: flex;
      margin-right: 5px;
    }
  }
  .el-drawer__body {
    min-height: calc(100vh - 133px);
    overflow-y: auto;
    padding: 0;
    padding-bottom: 55px;
  }
  .drawer-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 55px;
    background: #FAFAFA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 40px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #435BF4;
      margin-bottom: 0;
    }
    > img {
      cursor: pointer;
    }
  }

  @media (max-width: 770px) {
    width: 100% !important;
    .el-drawer__header {
      padding-top: 20px;
    }
  }
  @media (max-width: 500px) {
    .el-drawer__header {
      padding-left: 5px;
      padding-right: 5px;
    }
    .all-invited-drawer__header {
      h2 {
        font-size: 16px;
        span {
          font-size: 14px;
          margin-left: 5px;
        }
      }
      .btn {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 400px) {
    .all-invited-drawer__header {
      flex-direction: column;
      h2 {
        margin-bottom: 10px;
      }
    }
  }
}

.swal2-popup.delete-invitation {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 26px 16px;
  width: 497px;
  .swal2-header {
    padding: 0;
    align-items: flex-start;
    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #000000;
      margin-bottom: 0;
      text-align: left;
    }
  }
  .swal2-content {
    padding: 0;
    text-align: left;
  }
  .swal2-html-container {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 178.52%;
    color: #434343;
    opacity: 0.6;
  }
  .swal2-actions {
    margin: 0;
    justify-content: flex-start;
    margin-top: 25px;
    gap: 21px;
    button {
      margin: 0;
      transition: .3s ease-in-out;
      &.swal2-confirm {
        background: rgba(226, 45, 33, 0.1);
        border-radius: 59px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #E22D21;
        padding: 15px 18px;
      }
      &.swal2-cancel {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: rgba(67, 67, 67, 0.8);
        background: transparent;
        padding: 0;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
